@charset "UTF-8";

/*
 * 変数定義
 */
$key-color: #143374 !default;
$wrap-width: 960px !default;
$font-size-large: rem-calc(22) !default; /* 22px */
$font-size-medium: rem-calc(14) !default; /* 14px */
$font-size-small: rem-calc(12) !default; /* 12px */
$font-size-xsmall: rem-calc(10) !default; /* 10px */

/*
 * Mixin
 */
@mixin service-banner(
  $title-margin-bottom: 170,
  $project-size: 140%,
  $project-position: -30px -150px,
  $project-size-medium-down: 100%,
  $project-position-medium-down: 0 50%,
  $partner-size: cover,
  $partner-position: -55px 0px,
  $partner-size-medium-down: cover,
  $partner-position-medium-down: 0,
  $support-size: cover,
  $support-position: -100px 0px,
  $support-size-medium-down: cover,
  $support-position-medium-down: 0 40%,
  $title-font-size: $font-size-large,
  $title-jp-font-size: $font-size-medium,
) {
  .project,
  .partner,
  .support {
    a {
      background: no-repeat;
      background-size: cover;
      padding: rem-calc(15);
      color: $white;
      display: block;
      transition: all 0.5s 0s ease;

      @include breakpoint(medium down) {
        position: relative;
      }

      &:hover {
        opacity: 0.5;
      }

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-bottom: 25px solid transparent;
        border-left: 25px solid $key-color;
        position: relative;
        top: -15px;
        left: -15px;

        @include breakpoint(medium down) {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    div {
      font-size: $font-size-small;
      font-weight: 700;
    }

    .title {
      font-size: $title-font-size;
      margin-top: rem-calc(-25);
      margin-bottom: rem-calc($title-margin-bottom);

      @include breakpoint(medium down) {
        margin-bottom: rem-calc(10);
        margin-top: initial;
      }

      span {
        display: block;
        font-size: $title-jp-font-size;
      }
    }
  }

  .project {
    a {
      background-image: url('../img/bnr-projectleading.jpg');
      background-size: $project-size;
      background-position: $project-position;

      @include breakpoint(medium down) {
        background-size: $project-size-medium-down;
        background-position: $project-position-medium-down;
      }
    }
  }

  .partner {
    a {
      background-image: url('../img/bnr-partnersoucing.jpg');
      background-size: $partner-size;
      background-position: $partner-position;

      @include breakpoint(medium down) {
        background-size: $partner-size-medium-down;
        background-position: $partner-position-medium-down;
      }
    }
  }

  .support {
    a {
      background-image: url('../img/bnr-support.jpg');
      background-size: $support-size;
      background-position: $support-position;

      @include breakpoint(medium down) {
        background-size: $support-size-medium-down;
        background-position: $support-position-medium-down;
      }
    }
  }  
}

/*
 * Animation
 */
@keyframes logo {
  0%, 20% {
  }
  30%, 80% {
    opacity: 1;
    bottom: 0;
  }
  90%, 100% {
    opacity: 0;
    bottom: -5px;
  }
}

@keyframes menu_show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
 * 共通
 */
// Class
.color {
  color: $key-color !important;
}

// Hover Image
.hover {
  transition: all 0.5s 0s ease;

  &:hover {
    opacity: 0.5;
  }
}

// Callout
.callout {
  &.no-border {
    border: none;
  }

  &.block {
    display: block;
  }
}

// Margin
.no-margin-top {
  margin-top: 0 !important;
}

// Loading
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 10000;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: all .6s cubic-bezier(0.445, 0.05, 0.55, 0.95) .4s;

  &.complete {
    top: -100%;
  }

  &.accessed {
    display: none;
  }

  img {
    bottom: -30px;
    position: relative;
    animation: logo 2s ease-in-out .3s;
    opacity: 0;
  }
}

// Header
header.page-header {
  width: $wrap-width;
  margin: auto;
  padding-top: rem-calc(10);
  position: relative;
  z-index: 15;

  @include breakpoint (medium down) {
    width: auto;
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }

  h1 {
    img {
      @include breakpoint (medium down) {
        max-width: 150px;
      }
    }
  }
  .menu-bar {
    position: relative;
    z-index: 100;

    .humberger-icon {
      position: absolute;
      top: 20px;
      right: 10px;
      width: 25px;
      height: 23px;
      cursor: pointer;

      @include breakpoint(small down) {
        top: 10px;
      }

      span {
        position: absolute;
        right: 0;
        width: 100%;
        height: 2px;
        background: $key-color;
        transition: all 0.3s ease-out;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 10px;
        }

        &:nth-child(3) {
          top: 20px;
        }
      }

      &.open {
        span {
          transform: rotate(-45deg);
          top: 10px;

          &:nth-child(2) {
            transform: rotate(45deg);
          }

          &:nth-child(3) {
            opacity: 0;
          }          
        }        
      }
    }
  }

  nav {
    position: relative;
    z-index: 100;
    transition: all 0.3s ease-out;

    @include breakpoint(medium down) {
      position: absolute;
      top: 85px;
      right: 0px;
      z-index: 1000;
      background-color: rgba($key-color, 0.9);
    }

    @include breakpoint(small only) {
      top: 72px;
    }

    ul {
      animation: menu_show 0.3s ease-out;
    }

    ul.is-dropdown-submenu {
      left: 0 !important;
    }

    > ul {
      padding-top: rem-calc(10);

      li {
        margin-bottom: rem-calc(10);

        li {
          a {
            text-align: left;
            font-size: rem-calc(13);
            line-height: rem-calc(20);

            @include breakpoint(medium down) {
              text-align: center;
            }
          }
        }
      }

      > li {
        a {
          border-bottom: 2px solid transparent;
          transition: border-bottom .5s linear;

          @include breakpoint(medium down) {
            border-bottom-width: 0;
          }
        }

        &:hover {
          > a {
            border-bottom: 2px solid $key-color;

            @include breakpoint(medium down) {
              border-bottom-width: 0;
            }
          }
        }
      }

      a {
        color: $key-color !important;
        font-weight: 700;
        text-align: center;
        font-size: $font-size-medium;
        padding-bottom: 0 !important;

        @include breakpoint(medium down) {
          color: $white !important;
        }

        &:after {
          content: none !important;
        }

        span {
          display: block;
          font-weight: 400;
          font-size: $font-size-xsmall;
          margin: rem-calc(8) 0 rem-calc(20);
        }
      }

      &.dropdown.menu > li.is-dropdown-submenu-parent > a {
        padding-right: 1rem;
      }
    }
  }
}

// Footer
footer.page-footer {
  background-color: $key-color;
  color: $white;
  overflow: hidden;
  position: relative;

  .footer-inner {
    position: relative;
    z-index: 1;
    width: $wrap-width;
    margin: auto;
    padding: rem-calc(30) 0;

    @include breakpoint (medium down) {
      width: auto;
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
    }

    ul {
      list-style-type: none;
      margin: 0;

      li {
        font-size: $font-size-small;
        margin-bottom: rem-calc(5);
        font-weight: 700;

        &.title {
          font-size: $font-size-medium;
          margin-bottom: rem-calc(20);
        }

        @include breakpoint (medium down) {
          margin-bottom: rem-calc(10);        
        }
      }

      a {
        color: $white;
      }

      &:nth-child(3) {
        text-align: right;

        @include breakpoint (small only) {
          text-align: left;
          margin: rem-calc(30) 0;

          li {
            display: inline-block;
            margin-right: rem-calc(20);
          }
        }
      }
    }

    .copyright {
      text-align: right;
      font-size: $font-size-xsmall;

      @include breakpoint (small only) {
        text-align: center;        
      }
    }
  }
}

// Shapes
.shapes {
  @at-root footer.page-footer & {
    position: relative;
    left: -160px;
    top: -120px;
  }

  @at-root .page-title-wrapper & {
    position: relative;
    left: -610px;
    top: -310px;
    transition: left 0.3s ease-out;
    transition: top 0.3s ease-out;

    @include breakpoint(medium down) {
      left: -160px;
      top: -200px;    
    }
  }

  .diamond {
    background-color: $key-color;
    transform: rotate(-45deg);
    position: absolute;
    transition: all 0.3s ease-out;
  }

  .large {
    width: 170px;
    height: 170px;
    box-shadow: -30px 30px 80px rgba(0, 0, 0, 0.7);      
  }

  .medium {
    width: 110px;
    height: 110px;
    box-shadow: -20px 20px 52px rgba(0, 0, 0, 0.5);
  }

  .small {
    width: 75px;
    height: 75px;
    box-shadow: -14px 14px 36px rgba(0, 0, 0, 0.5);
  }

  .xsmall {
    width: 50px;
    height: 50px;
    box-shadow: -8px 8px 24px rgba(0, 0, 0, 0.5);
  }

  .l- {
    &1 {
      left: 70px;
      top: 20px;

      @include breakpoint (medium down) {
        left: -390px;
      }
    }

    &2 {
      left: 320px;
      top: 20px;

      @include breakpoint (medium down) {
        left: -150px;
      }
    }

    &3 {
      left: 75px;
      top: 265px;

      @include breakpoint (medium down) {
        left: -395px;
      }
    }

    &4 {
      left: 330px;
      top: 265px;

      @include breakpoint (medium down) {
        left: -100px;
      }    }

    &5 {
      left: 585px;
      top: 265px;

      @include breakpoint (medium down) {
        left: -110px;
      }    }
  }

  .m- {
    &1 {
      left: 80px;
      top: -40px;

      @include breakpoint (medium down) {
        left: -390px;
      }
    }

    &2 {
      left: 230px;
      top: 300px;

      @include breakpoint (medium down) {
        left: -240px;
      }
    }

    &3 {
      left: 510px;
      top: 330px;

      @include breakpoint (medium down) {
        left: 40px;
      }
    }

    &4 {
      left: 720px;
      top: 345px;

      @include breakpoint (medium down) {
        left: 250px;
      }
    }

    &5 {
      left: 980px;
      top: 395px;

      @include breakpoint (medium down) {
        left: -650px;
      }
    }
  }

  .s- {
    &1 {
      left: 390px;
      top: -80px;

      @include breakpoint (medium down) {
        left: -80px;
      }
    }

    &2 {
      left: 460px;
      top: 80px;

      @include breakpoint (medium down) {
        left: -10px;
      }
    }

    &3 {
      left: 620px;
      top: 170px;

      @include breakpoint (medium down) {
        left: 150px;
      }
    }
  }

  .xs- {
    &1 {
      left: 810px;
      top: 160px;

      @include breakpoint (medium down) {
        left: 340px;
      }
    }

    &2 {
      left: 880px;
      top: 270px;

      @include breakpoint (medium down) {
        left: 410px;
      }
    }

    &3 {
      left: 1040px;
      top: 240px;

      @include breakpoint (medium down) {
        left: 570px;
      }
    }

    &4 {
      left: 1110px;
      top: 140px;

      @include breakpoint (medium down) {
        left: 640px;
      }
    }
  }
}

// Overray shapes
.overlay-shape {
  position: absolute;
  top: 0;
  width: 100%;
  height: 200px;

  @include breakpoint(medium down) {
    display: none;
  }

  .shape-l,
  .shape-r,
  .shape-mask {
    overflow: hidden;
    position: absolute;
    transform: rotate(-45deg);
    width: 380px;
    height: 380px;

    &:after {
      content: '';
      background-color: rgba($key-color, 0.06);
      position: absolute;
      top: 0;
      width: 100%;
      height: 600px;
    }
  }

  .shape-l {
    left: -190px;
    top: 345px;
    z-index: 0;
  }

  .shape-r {
    right: -190px;
    top: 75px;
    z-index: 5;

    &:after {
      background-color: rgba($key-color, 0.5);
    }

    img {
      position: relative;
      transform: rotate(45deg);
      max-width: initial;
      width: 740px;
      top: -340px;
      left: -440px;
    }
  }

  &.page {
    .shape-mask {
      width: 100%;
      height: 539px;
      top: -5px;
      transform: initial;

      &:after {
        background: right top no-repeat transparent;
        background-image: url('../img/bg-mask.svg');
        background-size: auto;
        width: 100%;
      }
    }
  }
}

/*
 * Home
 */
.main-visual {
  background-color: $key-color;
  color: $white;
  overflow: hidden;
  position: relative;

  .image {
    .img-l,
    .img-r {
      overflow: hidden;
      position: absolute;
      z-index: 5;
      transform: rotate(-45deg);
      width: 380px;
      height: 380px;

      img {
        position: relative;
        transform: rotate(45deg);
        max-width: initial;
      }
    }

    .img-l {
      left: -190px;
      bottom: -190px;

      @include breakpoint(medium down) {
        left: -180px;
        bottom: -120px;
        width: 240px;
        height: 240px;
      }

      img {
        width: 580px;
        top: -45px;

        @include breakpoint(medium down) {
          width: 270px;
          top: 60px;
          left: 80px;
        }
      }
    }

    .img-r {
      right: -190px;
      bottom: 80px;

      @include breakpoint(medium down) {
        right: -120px;
        bottom: initial;
        top: 50px;
        width: 240px;
        height: 240px;
      }

      img {
        width: 740px;
        top: -340px;
        left: -440px;

        @include breakpoint(medium down) {
          width: 530px;
          top: -60px;
          left: -130px;
        }
      }
    }
  }

  .title {
    text-align: center;
    font-size: rem-calc(22);
    padding: rem-calc(150) 0;
    position: relative;
    z-index: 10;

    @include breakpoint(medium down) {
      font-size: rem-calc(20);
    }

    span {
      font-size: rem-calc(36);
      display: block;
      margin-bottom: rem-calc(20);

      @include breakpoint(medium down) {
        font-size: rem-calc(24);
        font-weight: 700;
      }
    }

    i {
      font-size: rem-calc(18);
      font-style: normal;
    }
  }
}

.main-menu {
  position: relative;
  z-index: 1;
  width: $wrap-width;
  margin: auto;
  padding: rem-calc(60) 0;
  overflow: hidden;

  @include breakpoint(medium down) {
    width: auto;
    padding: 0;
  }

  @include service-banner();

  @include breakpoint(medium down) {
    .project,
    .partner,
    .support {
      a {
        background-size: cover;
        background-position: center;        
        padding-top: 250px;
      }
    }
  }

  @include breakpoint(small only) {
    .project,
    .partner,
    .support {
      a {
        background-size: 180%;
      }
    }

    .project {
      a {
        background-position: 0px;
      }
    }

    .partner {
      a {
        background-position: 40%;
      }
    }

    .support {
      a {
        background-position: 70%;
      }
    }
  }
}

/*
 * Pages - 下層ページ
 */
body.page {
  .page-title-wrapper {
    background-color: $key-color;
    color: $white;
    padding: rem-calc(40) 0;
    margin-bottom: rem-calc(30);
    overflow: hidden;

    .page-title {
      width: $wrap-width;
      margin: auto;

      @include breakpoint (medium down) {
        width: auto;
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
      }

      h1 {
        font-size: rem-calc(28);
        margin-bottom: 0;
        z-index: 1;
        position: relative;

        @include breakpoint (medium down) {
          text-align: center;
        }

        @include breakpoint (small only) {
          font-size: rem-calc(20);
        }

        span {
          display: block;
          font-size: rem-calc(16);
          margin-left: rem-calc(3);
          margin-top: rem-calc(10);

          @include breakpoint (small only) {
            font-size: rem-calc(12);
          }
        }
      }
    }
  }

  .wrapper {
    width: $wrap-width;
    margin: auto auto rem-calc(150);
    overflow: hidden;
    position: relative;
    z-index: 10;

    @include breakpoint (medium down) {
      width: auto;
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
    }

    .breadcrumb {

    }

    main {
      width: 670px;
      float: left;

      @include breakpoint (medium down) {
        width: auto;
        float: none;
        margin-bottom: rem-calc(50);
      }

      & > *:first-child {
        margin-top: 0;
      }

      .divided-wrapper {
        margin-top: rem-calc(40);

        .divided {
          border-top: 1px solid #dddddd;
          padding: rem-calc(20) 0;

          &:nth-last-of-type(1) {
            border-bottom: 1px solid #dddddd;
          }

          > div {
            border-left: 6px solid $key-color;
            font-weight: 700;
            font-size: rem-calc(15);
            color: $key-color;
            padding: rem-calc(5) 0 rem-calc(5) rem-calc(20);

            ul {
              margin: rem-calc(10) 0 0 2rem;

              @include breakpoint(small only) {
                margin-left: 0;
                list-style-position: inside;
              }
            }
          }
        }        
      }

      $e: &; // -> body.page .wrapper main
      img.bnr {
        margin-top: rem-calc(40);

        @at-root {
          #{$e} > *:first-child img.bnr {
            margin-top: 0;
          }
        }
      }

      .block-oblique {
        margin-top: rem-calc(40);
        padding: rem-calc(40);
        background: repeating-linear-gradient(
          -45deg,
          #f7f7f7,
          #f7f7f7 10px,
          #fafafa 10px,
          #fafafa 20px
        );

        @include breakpoint(small only) {
          padding: rem-calc(35) rem-calc(20);
        }

        dt {
          border-bottom: 1px solid #dddddd;
          font-size: rem-calc(20);
          padding-bottom: rem-calc(20);
          margin-bottom: rem-calc(20);

          @include breakpoint(small only) {
            text-align: center;
          }
        }

        .grid-x {
          margin-top: 0;
        }

        ul,
        & {
          margin-bottom: 0;
          list-style-type: square;
          list-style-position: inside;

          li {
            line-height: 2;
            font-size: $font-size-medium;
          }
        }

        &.border-list {
          padding: rem-calc(30) rem-calc(20);
          margin-left: 0;

          li {
            border-bottom: 1px solid #dddddd;
            padding-bottom: rem-calc(10);
            margin-bottom: rem-calc(10); 
            list-style-position: inside;

            &:last-of-type {
              border-bottom: 0;
              padding-bottom: 0;
              margin-bottom: 0;
            }
          }
        }
      }

      h2 {
        background-color: $key-color;
        color: $white;
        padding: rem-calc(20) rem-calc(30);
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(20);
        font-size: $font-size-large;
        font-weight: 700;
        position: relative;
        overflow: hidden;

        &::before,
        &::after {
          content: '';
          display: block;
          width: 150px;
          height: 150px;
          background-color: rgba($white, 0.15);
          position: absolute;
          transform: rotate(45deg);
          right: -75px;
          top: 30px;
        }

        &::after {
          transform: rotate(-45deg);
          right: -110px;
          top: -75px;
        }

        @include breakpoint(small only) {
          font-size: rem-calc(16);
          padding: rem-calc(15);
        }

        span {
          font-size: $font-size-medium;
          margin-left: rem-calc(10);
        }
      }

      h3 {
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(20);
        font-size: rem-calc(20);
        font-weight: 700;

        @include breakpoint(small only) {
          font-size: rem-calc(18);
        }

        .question,
        .answer {
          background-color: $key-color;
          color: $white;
          padding: rem-calc(6) rem-calc(8);
          border-radius: 50%;
        }

        .answer {
          background-color: #F5B119;
        }
      }

      h4 {
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(20);
        font-size: rem-calc(18);
      }

      .grid-x,
      .margin-top {
        margin-top: rem-calc(40);

        &:first-child {
          margin-top: 0;
        }
      }

      .caption {
        font-size: $font-size-small;

        img {
          margin-bottom: rem-calc(10);
        }
      }

      table {
        margin-top: rem-calc(40);

        tbody,
        tr,
        th {
          border-color: #cccccc;
        }

        th,
        td {
          padding-top: rem-calc(15);
          padding-bottom: rem-calc(15)
        }

        th {
          background-color: #f7f7f7;
          font-weight: 700;
          border-right: 1px solid #cccccc;
        }

        &.different {
          th {
            width: 10%;
            background-color: rgba($key-color, 0.1);
            font-weight: bold;
            text-align: center;
          }

          td {
            width: 40%;
          }
        }
      }

      .heading {
          text-align: center;
          margin-top: rem-calc(50);
          font-size: rem-calc(36);
          font-weight: bold;

          @include breakpoint(small only) {
            font-size: rem-calc(24);
          }
      }

      .check {
          margin-top: rem-calc(50);
          margin-bottom: rem-calc(50);
          margin-left: 0;
          text-align: center;
          list-style-type: none;

          li {
              font-size: rem-calc(20);
              margin-bottom: rem-calc(20);

              @include breakpoint(small only) {
                font-size: rem-calc(18);
              }
          }
      }

      a.contactbtn {
        padding: 18px 14px 18px 20px;
        font-weight: bold;
        background: #16336d url(../img/btn-contact.png) no-repeat 101% 50%;
        box-sizing: border-box;
        color: $white;
      }
    }

    aside {
      width: 240px;
      float: right;

      @include breakpoint (medium down) {
        width: auto;
        float: none;
      }

      ul {
        margin-left: 0;
        list-style: none;
      }

      ul.local-menu {
        margin-bottom: rem-calc(20);

        li {
          a {
            display: block;
            background-color: $key-color;
            color: $white;
            padding: rem-calc(20);
            font-size: rem-calc(13);
            font-weight: 700;
            transition: background-color .5s linear;

            &:hover {
              background-color: #0d214a;
            }
          }

          &.parent {
            margin-bottom: rem-calc(1);

            a {
              text-align: center;
              font-size: rem-calc(18);
              padding: rem-calc(30) 0;
            }
          }
        }
      }

      ul.banner {
        @include service-banner(40, 150%, -30px -150px, 100%, 0 50%, 120%, -20px 0px, cover, 0, 115%, -20px 0px, cover, 0 40%, rem-calc(18), rem-calc(12));

        li {
          margin-bottom: rem-calc(5);
        }
      }
    }
  }
}